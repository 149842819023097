import { ShiftAvailabilityStatus, TakerAvailabilitiesAdmin } from "@/model/ShiftAvailability";
import { HttpService } from "./base/http.service";

export interface ChangeAvailabilityStatus {
    entity_id : number;
    shift_id  : number;
    zone_id   : number;
    date      : string;
    status    ?: ShiftAvailabilityStatus;
}

class AvailabilityService extends HttpService {
    readonly endPoint = `admin/shift-availabilities`;

    public getTakersAvailabilities(zone_id: number, date_from: Date, date_to: Date) {
        return this.get<TakerAvailabilitiesAdmin[]>(`${this.endPoint}`, {
            params: { date_from, date_to, zone_id }
        });
    }
    
    public createAvailability(data: ChangeAvailabilityStatus) {
        data.status = ShiftAvailabilityStatus.CREATED;
        return this.post<any>(`${this.endPoint}`, data);
    }

    public confirmAvailability(data: ChangeAvailabilityStatus) {
        data.status = ShiftAvailabilityStatus.CONFIRMED;
        return this.post<any>(`${this.endPoint}`, data);
    }

    public declineAvailability(data: ChangeAvailabilityStatus) {
        data.status = ShiftAvailabilityStatus.CREATED;
        return this.post<any>(`${this.endPoint}`, data);
    }

    public deleteAvailability(avId: number) {
        return this.delete<any>(`${this.endPoint}/${avId}`);
    }
}

export const availabilityService = new AvailabilityService();



