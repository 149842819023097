import { Shift } from "./Shift";


export class TakerAvailabilitiesAdmin {
    id: number;

    /**
     * Taker name
     */
    name: string; 

    /**
     * Taker surname
     */
    surname: string; 

    shift_availabilities: ShiftAvailability[];
}


export class ShiftAvailability {
    id        : number;
    entity_id : number;
    
    status: ShiftAvailabilityStatus;

    /**
     * Eg: "2021-07-12"
     */
    date: string;

    shift_id: number;
    shift: Shift;

    zone: ZoneName;
    zone_id: number;
}

export interface ZoneName{
    id: number; 
    name: string; 
}

export enum ShiftAvailabilityStatus {
    CREATED   = 10,
    CONFIRMED = 20
}