import { Options, Vue } from "vue-class-component";
import { Prop }         from "vue-property-decorator";

import { Shift }                                             from "@/model/Shift";
import { Zone }                                              from "@/model/Zone";
import { ShiftAvailabilityStatus, TakerAvailabilitiesAdmin } from "@/model/ShiftAvailability";

import { shiftsService }       from "@services/shift.service";
import { availabilityService } from "@services/availability.service";

import * as Moment                                     from 'moment';
import { DateRange, extendMoment }                     from 'moment-range';
import { PageHeader, WeekNavigator, ZoneAutocomplete } from "@/components"

import { TableSkeleton } from "@sharedComponents";

import { TakersRoutesEnum } from "../../router";
import { zonesService }     from "@services/zones.service";

const moment = extendMoment( Moment );

@Options( {
    components: {
        TableSkeleton,
        WeekNavigator,
        ZoneAutocomplete,
        PageHeader
    }
} )
export default class AvailabilityPage extends Vue {
    @Prop() readonly dates!: DateRange;
    @Prop() readonly zoneId!: number;

    /**
     * Zona selezionata in fase di filtro
     */
    selectedZone: Zone = null;

    /**
     * Settimana selezionata
     */
    range: DateRange = null;

    /**
     * Giorni che compongono la settimana selezionata
     */
    days: Moment.Moment[] = null;

    /**
     * Fasce orarie censite
     */
    shifts: Shift[] = null;

    /**
     * Taker e Disponibilità risultanti
     */
    dataset: TakerAvailabilitiesAdmin[] = null;
    requestCount: number                = 0;

    get weekDays() {
        return this.days?.map( x => x.format( 'dddd' ).capitalize() );
    }

    /**
     * Ritorna i giorni selezionati
     * [week day] day/month/year
     */
    get formattedDays() {
        return this.days?.map( x => x.format( 'dddd DD/MMM/YY' ).capitalize() );
    }

    onUpdateRange() {
        this.days = [ ...this.range.by( 'day' ) ];

        if (this.selectedZone) {
            this.search();
        } else {
            this.dataset = null;
        }
    }

    shiftDescritpion( index: number ) {
        const s = this.shifts[index];
        return `${ s.name } | ${ s.start_time } - ${ s.end_time } |`;
    }

    dateOf( index: number, shift?: Shift ) {
        return shift
            ? `${ this.formattedDays[index] } \n [${ shift.start_time } - ${ shift.end_time }]`
            : `${ this.formattedDays[index] }`;
    }

    avExists( dateIndex: number, ta: TakerAvailabilitiesAdmin, shiftId: number ) {
        const date = this.days[dateIndex].format( "YYYY-MM-DD" );

        return !!ta.shift_availabilities
                   .find( s => s.shift_id === shiftId && s.date === date );
    }

    isConfirmed( dateIndex: number, ta: TakerAvailabilitiesAdmin, shiftId: number ) {
        const date = this.days[dateIndex].format( "YYYY-MM-DD" );

        return ta.shift_availabilities
                 .find( s => s.shift_id === shiftId && s.date === date )
            .status === ShiftAvailabilityStatus.CONFIRMED;
    }

    onTaClick( dateIndex: number, ta: TakerAvailabilitiesAdmin, shiftId: number ) {
        const date        = this.days[dateIndex].format( "YYYY-MM-DD" );
        const isConfirmed = this.isConfirmed( dateIndex, ta, shiftId );

        if (isConfirmed) {
            this.declineAvailability( date, ta.id, shiftId );
        } else {
            this.confirmAvailability( date, ta.id, shiftId )
        }

    }

    async search() {
        this.updateRoute();

        this.$waitFor(
            async () => {
                this.requestCount++;

                this.dataset = await availabilityService.getTakersAvailabilities(
                    this.selectedZone.id,
                    this.range.start.toDate(),
                    this.range.end.toDate()
                );
            }
        )
    }

    created() {
        this.initRange();
        this.loadShift();
        this.useQueryParams();
    }

    errorCaptured( err: Error ) {
        this.$errorMessage( err.message );
        console.error( err );
        return false;
    }

    private confirmAvailability(
        date: string,
        entity_id: number,
        shift_id: number
    ) {


        this.$waitFor(
            async () => {
                const response = await availabilityService.confirmAvailability( {
                    date,
                    shift_id,
                    entity_id,
                    zone_id: this.selectedZone.id
                } );

                const av = this.dataset
                               .find( x => x.id === entity_id )
                               .shift_availabilities
                               .find( x => x.date === date && x.shift_id === shift_id );

                av.status = ShiftAvailabilityStatus.CONFIRMED;

                this.$successMessage( "Disponibilità confermata" );
            },

            "Conferma disponibilità non riuscita"
        )
    }

    private declineAvailability(
        date: string,
        entity_id: number,
        shift_id: number
    ) {


        this.$waitFor(
            async () => {
                const response = await availabilityService.declineAvailability( {
                    date,
                    shift_id,
                    entity_id,
                    zone_id: this.selectedZone.id
                } );

                const av = this.dataset
                               .find( x => x.id === entity_id )
                               .shift_availabilities
                               .find( x => x.date === date && x.shift_id === shift_id );

                av.status = ShiftAvailabilityStatus.CREATED;

                this.$successMessage( "Disponibilità esclusa" );
            },

            "Cancellazione disponibilità non riuscita"
        )
    }

    private updateRoute() {
        this.$router.replace( {
            name : TakersRoutesEnum.AVAILABILITY,
            query: {
                dates : this.range.toString(),
                zoneId: this.selectedZone?.id
            },
        } )
    }

    /**
     * Inizializza il periodo con la settimana corrente
     */
    private initRange() {
        this.range = moment.range(
            moment().startOf( 'week' ),
            moment().endOf( 'week' )
        );

        this.days = [ ...this.range.by( 'day' ) ];
    }

    /**
     * Carica tutte le fasce orarie disponibili
     */
    private async loadShift() {
        this.shifts = await shiftsService.getAll();
    }

    private async loadZone( id: number ) {
        return zonesService.getById( id );
    }

    private async useQueryParams() {
        if (this.zoneId && this.dates) {
            this.selectedZone = await this.loadZone( this.zoneId );
            this.range        = this.dates;

            this.search();
        }
    }
}
